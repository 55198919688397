<template>
  <el-form :model="userInfo" ref="ruleForm" label-width="120px" class="demo-ruleForm">
    <div class="sub_title"><span style="color:red">'证件号码'</span>如有X字符,请使用<span style="color:red">大写英文字母X</span></div>
    <el-form-item label="姓名" required>
      <el-col :span="9">
        <el-form-item prop="name" :rules="[{required: true, message: '请输入姓名', trigger: 'blur'}]">
          <el-input v-model="userInfo.name"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="手机" required>
      <el-col :span="9">
        <el-form-item prop="mobile" :rules="[{required: true, message: '请输入手机号', trigger: 'blur'}]">
          <el-input v-model="userInfo.mobile"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>

    <el-form-item label="证件类型" required>
      <el-col :span="9">
        <el-form-item
          prop="idCardType"
          :rules="[
            {
              required: true,
              message: '请选择证件类型',
              trigger: 'change',
            },
          ]"
        >
          <el-select v-model="userInfo.idCardType" @change="cardtype" style="width: 100%">
            <el-option
              v-for="item in cardTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item
          label="证件号码"
          prop="idCard"
          :rules="[
            {
              required: true,
              message: '请输入证件号码',
              trigger: 'blur',
            },
          ]"
        >
          <el-input type="text" @blur="getAgeSexBirthdayByIdcard" v-model.trim="userInfo.idCard"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="出生日期" required>
      <el-col :span="9">
        <el-form-item
          prop="birthday"
          :rules="[
            {
              required: true,
              message: '请选择出生日期',
              trigger: 'change',
            },
          ]"
        >
          <el-date-picker
            style="width: 100%"
            :disabled="isDisable"
            v-model="userInfo.birthday"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择出生日期"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <!-- nationality -->
      <el-col :span="13">
        <el-form-item
          label="性别"
          prop="sex"
          :rules="[
            {
              required: true,
              message: '请选择性别',
              trigger: 'change',
            },
          ]"
        >
          <el-select v-model="userInfo.sex" style="width: 100%" :disabled="isDisable">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
            <!-- <el-option label="未知" :value="0"></el-option> -->
          </el-select>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="民族" required>
      <el-col :span="9">
        <el-form-item
          prop="nationality"
          :rules="[
            {
              required: true,
              message: '请选择民族',
              trigger: 'change',
            },
          ]"
        >
          <el-select v-model="userInfo.nationality" style="width: 100%">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in nationalityList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item
          prop="workUnitName"
          label="所在单位"
          :rules="[
            {
              required: true,
              message: '请填写所在单位',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="userInfo.workUnitName"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="劳务关系" required>
      <el-col :span="9">
        <el-form-item
          prop="laborRelation"
          :rules="[
            {
              required: true,
              message: '请选择劳务关系',
              trigger: 'change',
            },
          ]"
        >
          <el-select v-model="userInfo.laborRelation" style="width: 100%">
            <el-option
              v-for="item in laborRelationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="工龄" prop="workYear">
          <el-input type="text" v-model="userInfo.workYear"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="文化程度" required>
      <el-col :span="9">
        <el-form-item
          prop="education"
          :rules="[
            {
              required: true,
              message: '请选择文化程度',
              trigger: 'change',
            },
          ]"
        >
          <el-select v-model="userInfo.education" style="width: 100%">
            <el-option
              v-for="item in eductionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item
          label="考生来源"
          prop="source"
          :rules="[
            {
              required: true,
              message: '请选择考生来源',
              trigger: 'change',
            },
          ]"
        >
          <el-select v-model="userInfo.source" style="width: 100%">
            <el-option
              v-for="item in mySourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="邮箱地址" required>
      <el-col :span="9">
        <el-form-item
          prop="email"
          :rules="[
            {
              required: true,
              message: '请输入邮箱地址',
              trigger: 'blur',
            },
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input v-model="userInfo.email"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="证书获取途径" required>
      <el-col :span="9">
        <el-form-item
          prop="gainCertType"
          :rules="[
            {
              required: true,
              message: '请选择证书获取途径',
              trigger: 'change',
            },
          ]"
        >
          <el-radio-group v-model="userInfo.gainCertType">
            <el-radio :label="1">自取</el-radio>
            <el-radio :label="2">邮寄</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-form-item>
    <div v-if="userInfo.gainCertType == 2">
      <el-form-item label="收件人" required>
        <el-col :span="9">
          <el-form-item
            prop="addressee"
            :rules="[
              {required: true, message: '请输入收件人', trigger: 'blur'},
            ]"
          >
            <el-input v-model="userInfo.addressee"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item
            label="联系电话"
            prop="contactPhone"
            :rules="[
              {required: true, message: '联系电话', trigger: 'blur'},
              {
                pattern: /^1[3|5|7|8|9]\d{9}$/,
                message: '请输入正确的号码格式',
                trigger: 'change',
              },
            ]"
          >
            <el-input v-model="userInfo.contactPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="address"
        :rules="[{required: true, message: '详细地址', trigger: 'blur'}]"
        required
      >
        <el-input v-model="userInfo.address" style="width: 91%"></el-input>
      </el-form-item>
    </div>
    <div class="my_btn">
      <el-button type="info" plain @click="closeInfo">取消</el-button>
      <el-button type="primary" class="wait" @click="submitForm('ruleForm')">确定</el-button>
    </div>
  </el-form>
</template>

<script>
import { updateUserInfo, getUserInfo } from "@/util/api";
import { mapMutations, mapState } from "vuex";
import {
  getBirthdayByIdCard,
  getSexByIdCard,
  getAgeByIdCard
} from "@/util/common.js";

export default {
  data() {
    return {
      cardTypeList: [
        { value: 1, label: "居民身份证" },
        { value: 2, label: "中国人民解放军军官证" },
        { value: 3, label: "香港特区护照/港澳居民来往内地通行证" },
        { value: 4, label: "澳门特区护照/港澳居民来往内地通行证" },
        { value: 5, label: "台湾居民来往大陆通行证" },
        { value: 6, label: "外国人永久居留证" },
        { value: 7, label: "外国人护照" },
        { value: 8, label: "港澳台居民居住证" },
        { value: 9, label: "香港居民身份证" },
        { value: 10, label: "澳门居民身份证" },
        { value: 11, label: "台湾居民身份证" },
        { value: 12, label: "社会保障卡" },
        { value: 13, label: "其他身份证件" }
      ],
      laborRelationList: [
        { value: 1, label: "本企业职工" },
        { value: 2, label: "劳务派遣职工" },
        { value: 3, label: "其他" },
        { value: 0, label: "无" }
      ],
      eductionList: [
        { value: 0, label: "小学" },
        { value: 1, label: "初级中学" },
        { value: 2, label: "普通中学" },
        { value: 3, label: "技工学校" },
        { value: 4, label: "职业高中" },
        { value: 5, label: "中等专科" },
        { value: 6, label: "大学专科" },
        { value: 7, label: "大学本科" },
        { value: 8, label: "硕士研究生" },
        { value: 9, label: "博士研究生" },
        { value: 11, label: "高技" },
        { value: 10, label: "其他" }
      ],
      sourceList: [
        { value: 1, label: "企业职工" },
        { value: 2, label: "在校学生" },
        { value: 3, label: "下岗失业人员" },
        { value: 0, label: "其他人员" }
      ],
      tJSourceList: [
        { value: 1, label: "企业职工" },
        { value: 2, label: "在校学生" },
        { value: 3, label: "下岗失业人员" },
        { value: 0, label: "其他人员" },
        { value: 6, label: "院校学生-普通大专" },
        { value: 7, label: "院校学生-普通大学" },
        { value: 8, label: "企业职工-国有企业" },
        { value: 9, label: "企业职工-集体企业" },
        { value: 10, label: "企业职工-私营企业" },
        { value: 11, label: "企业职工-个体企业" }
      ],
      nationalityList: [
        { value: "01", label: "汉族" },
        { value: "02", label: "蒙古族" },
        { value: "03", label: "回族" },
        { value: "04", label: "藏族" },
        { value: "05", label: "维吾尔族" },
        { value: "06", label: "苗族" },
        { value: "07", label: "彝族" },
        { value: "08", label: "壮族" },
        { value: "09", label: "布依族" },
        { value: "10", label: "朝鲜族" },
        { value: "11", label: "满族" },
        { value: "12", label: "侗族" },
        { value: "13", label: "瑶族" },
        { value: "14", label: "白族" },
        { value: "15", label: "土家族" },
        { value: "16", label: "哈尼族" },
        { value: "17", label: "哈萨克族" },
        { value: "18", label: "傣族" },
        { value: "19", label: "黎族" },
        { value: "20", label: "傈傈族" },
        { value: "21", label: "佤族" },
        { value: "22", label: "畲族" },
        { value: "23", label: "高山族" },
        { value: "24", label: "拉祜族" },
        { value: "25", label: "水族" },
        { value: "26", label: "东乡族" },
        { value: "27", label: "纳西族" },
        { value: "28", label: "景颇族" },
        { value: "29", label: "柯尔克孜族" },
        { value: "30", label: "土族" },
        { value: "31", label: "达翰尔族" },
        { value: "32", label: "仫佬族" },
        { value: "33", label: "羌族" },
        { value: "34", label: "布朗族" },
        { value: "35", label: "撒拉族" },
        { value: "36", label: "毛南族" },
        { value: "37", label: "仡佬族" },
        { value: "38", label: "锡伯族" },
        { value: "39", label: "阿昌族" },
        { value: "40", label: "普米族" },
        { value: "41", label: "塔吉克族" },
        { value: "42", label: "怒族" },
        { value: "43", label: "乌孜别克族" },
        { value: "44", label: "俄罗斯族" },
        { value: "45", label: "鄂温克族" },
        { value: "46", label: "德昂族" },
        { value: "47", label: "保安族" },
        { value: "48", label: "裕固族" },
        { value: "49", label: "京族" },
        { value: "50", label: "塔塔尔族" },
        { value: "51", label: "独龙族" },
        { value: "52", label: "鄂伦春族" },
        { value: "53", label: "赫哲族" },
        { value: "54", label: "门巴族" },
        { value: "55", label: "珞巴族" },
        { value: "56", label: "基诺族" },
        { value: "57", label: "外国血统" },
        { value: "99", label: "其他" }
      ],
      itemId: null,
      isDisable: false,
      oldUserInfo: "",
      mySourceList: undefined
    };
  },
  computed: {
    ...mapState(["userInfo", "isChange"])
  },
  mounted() {
    this.getAgeSexBirthdayByIdcard();
    this.oldUserInfo = this.userInfo;
    if (this.userInfo.idCard) {
      this.getAgeSexBirthdayByIdcard();
    }
  },
  methods: {
    isTJ() {
      // console.log(this.form.companyId,'wwwwwwwww');c1b248d4c4a94b7392c297d433af6060
      if (this.userInfo.companyId == "c1b248d4c4a94b7392c297d433af6060") {
        // console.log('11111111',this.baseInfoList.tJSourceList);
        this.mySourceList = this.tJSourceList;
      } else {
        // console.log('22222222',this.baseInfoList.sourceList);
        this.mySourceList = this.sourceList;
      }
    },
    ...mapMutations(["changeUserInfo", "changeShowInfo", "changeisChange"]),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          updateUserInfo(this.userInfo)
            .then(res => {
              this.$notify({
                title: "成功",
                message: "提交成功！",
                type: "success"
              });
              this.changeUserInfo(this.userInfo);
              this.changeisChange(true);
              this.closeInfo();
            })
            .catch(() => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getAgeSexBirthdayByIdcard() {
      if (this.userInfo.idCardType == 1 && this.userInfo.idCard) {
        this.isDisable = true;
        this.userInfo.birthday = getBirthdayByIdCard(this.userInfo.idCard);
        this.userInfo.sex = Number(getSexByIdCard(this.userInfo.idCard));
      }
      this.isTJ();
    },
    cardtype(e) {
      if (e == 1) {
        this.isDisable = true;
        return;
      }
      this.userInfo.idCard = null;
      this.userInfo.sex = null;
      this.userInfo.birthday = null;
      this.isDisable = false;
    },
    closeInfo() {
      this.changeShowInfo(false);
      getUserInfo().then(res => {
        this.user = res.data;
        this.changeUserInfo(res.data);
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.my_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 120px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    border-radius: 10px;
    margin: 30px 60px;
  }
}
.wait {
  background-color: #2b68d9;
}
</style>
<style scoped>
.demo-ruleForm >>> .el-input__inner {
  border-radius: 10px;
  height: 32px;
  line-height: 32px;
}
.sub_title {
  text-align: center;
}
</style>
